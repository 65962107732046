pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  background: #2e3440;
}

.hljs, .hljs-subst {
  color: #d8dee9;
}

.hljs-selector-tag {
  color: #81a1c1;
}

.hljs-selector-id {
  color: #8fbcbb;
  font-weight: bold;
}

.hljs-selector-class, .hljs-selector-attr {
  color: #8fbcbb;
}

.hljs-property, .hljs-selector-pseudo {
  color: #88c0d0;
}

.hljs-addition {
  background-color: rgba(163, 190, 140, .5);
}

.hljs-deletion {
  background-color: rgba(191, 97, 106, .5);
}

.hljs-built_in, .hljs-type, .hljs-class {
  color: #8fbcbb;
}

.hljs-function, .hljs-title.hljs-function, .hljs-function > .hljs-title {
  color: #88c0d0;
}

.hljs-keyword, .hljs-literal, .hljs-symbol {
  color: #81a1c1;
}

.hljs-number {
  color: #b48ead;
}

.hljs-regexp {
  color: #ebcb8b;
}

.hljs-string {
  color: #a3be8c;
}

.hljs-title {
  color: #8fbcbb;
}

.hljs-params {
  color: #d8dee9;
}

.hljs-bullet {
  color: #81a1c1;
}

.hljs-code {
  color: #8fbcbb;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-formula {
  color: #8fbcbb;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link:hover {
  text-decoration: underline;
}

.hljs-quote, .hljs-comment {
  color: #4c566a;
}

.hljs-doctag {
  color: #8fbcbb;
}

.hljs-meta, .hljs-meta .hljs-keyword {
  color: #5e81ac;
}

.hljs-meta .hljs-string {
  color: #a3be8c;
}

.hljs-attr {
  color: #8fbcbb;
}

.hljs-attribute {
  color: #d8dee9;
}

.hljs-name {
  color: #81a1c1;
}

.hljs-section {
  color: #88c0d0;
}

.hljs-tag {
  color: #81a1c1;
}

.hljs-variable, .hljs-template-variable {
  color: #d8dee9;
}

.hljs-template-tag {
  color: #5e81ac;
}

.language-abnf .hljs-attribute {
  color: #88c0d0;
}

.language-abnf .hljs-symbol {
  color: #ebcb8b;
}

.language-apache .hljs-attribute {
  color: #88c0d0;
}

.language-apache .hljs-section {
  color: #81a1c1;
}

.language-arduino .hljs-built_in {
  color: #88c0d0;
}

.language-aspectj .hljs-meta {
  color: #d08770;
}

.language-aspectj > .hljs-title {
  color: #88c0d0;
}

.language-bnf .hljs-attribute {
  color: #8fbcbb;
}

.language-clojure .hljs-name {
  color: #88c0d0;
}

.language-clojure .hljs-symbol {
  color: #ebcb8b;
}

.language-coq .hljs-built_in {
  color: #88c0d0;
}

.language-cpp .hljs-meta .hljs-string {
  color: #8fbcbb;
}

.language-css .hljs-built_in {
  color: #88c0d0;
}

.language-css .hljs-keyword {
  color: #d08770;
}

.language-diff .hljs-meta, .language-ebnf .hljs-attribute {
  color: #8fbcbb;
}

.language-glsl .hljs-built_in {
  color: #88c0d0;
}

.language-groovy .hljs-meta:not(:first-child), .language-haxe .hljs-meta, .language-java .hljs-meta {
  color: #d08770;
}

.language-ldif .hljs-attribute {
  color: #8fbcbb;
}

.language-lisp .hljs-name, .language-lua .hljs-built_in, .language-moonscript .hljs-built_in, .language-nginx .hljs-attribute {
  color: #88c0d0;
}

.language-nginx .hljs-section {
  color: #5e81ac;
}

.language-pf .hljs-built_in, .language-processing .hljs-built_in {
  color: #88c0d0;
}

.language-scss .hljs-keyword, .language-stylus .hljs-keyword {
  color: #81a1c1;
}

.language-swift .hljs-meta {
  color: #d08770;
}

.language-vim .hljs-built_in {
  color: #88c0d0;
  font-style: italic;
}

.language-yaml .hljs-meta {
  color: #d08770;
}

.avod-web-player-media {
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.avod-web-player-media video::cue {
  visibility: hidden;
}

.avod-web-player-media video::-webkit-media-text-track-display {
  display: none;
}

.avod-web-player-media video.main {
  object-fit: contain;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  width: 100%;
  display: block;
}

.avod-web-player-media video.padding-if-no-src:not([src]) {
  padding-bottom: var(--aspect-ratio, 56.25%);
  height: 0;
}

.avod-web-player-media .avod-web-player-csai {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-media .avod-web-player-csai video {
  width: 100%;
  height: 100%;
}

.avod-web-player-media .avod-web-player-pause {
  pointer-events: auto;
  cursor: pointer;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-media .avod-web-player-pause .pause-image {
  object-fit: contain;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.avod-web-player-media .avod-web-player-pause .pause-image:not([src]) {
  visibility: hidden;
}

.visit-advertiser-link {
  visibility: hidden;
  z-index: 2;
  color: #fff;
  cursor: pointer;
  background: rgba(0, 0, 0, .32);
  border: none;
  border-radius: 6px;
  -ms-flex-align: center;
  align-items: center;
  padding: .3em .6em;
  font-size: 1em;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 5em;
  right: 2em;
}

.visit-advertiser-link.desktop-small {
  bottom: 4em;
  right: 1em;
}

.visit-advertiser-link.mobile {
  bottom: 2.5em;
  right: 1em;
}

.visit-advertiser-link.smallest {
  font-size: .6em;
  bottom: 4.5em;
  right: .5em;
}

.visit-advertiser-link svg {
  fill: #fff;
  height: 2.5em;
}

.visit-advertiser-link.mobile svg {
  height: 2em;
}

.fullscreen {
  background-color: #000;
}

.fullscreen #container {
  position: static;
}

.avod-web-player-canvas-background {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-fake-fullscreen {
  background: #000;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.avod-web-player-fake-fullscreen > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-fake-fullscreen #container {
  position: static;
}

.avod-web-player-container {
  padding-top: var(--aspect-ratio, 56.25%);
  height: 0;
  position: relative;
  container: playerContainer / inline-size;
}

.avod-web-player-container .dev_mode_notification {
  color: rgba(255, 255, 255, .5);
  text-shadow: 1px 1px rgba(255, 255, 255, .2), -1px -1px rgba(0, 0, 0, .2);
  z-index: 1;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.avod-web-player-container.fullscreen, .avod-web-player-container.avod-web-player-fake-fullscreen {
  padding-top: 0;
  position: static;
}

.avod-web-player-media, .avod-web-player-skin {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-skin {
  --button-size: 24px;
}

@container playerContainer (width > 768px) {
  .avod-web-player-skin {
    --button-size: 32px;
  }
}

button {
  font-family: inherit;
}

.avod-web-player-container {
  padding-top: var(--aspect-ratio, 56.25%);
  height: 0;
  position: relative;
  container: playerContainer / inline-size;
}

.avod-web-player-container .dev_mode_notification {
  color: rgba(255, 255, 255, .5);
  text-shadow: 1px 1px rgba(255, 255, 255, .2), -1px -1px rgba(0, 0, 0, .2);
  z-index: 1;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.avod-web-player-container.fullscreen, .avod-web-player-container.avod-web-player-fake-fullscreen {
  padding-top: 0;
  position: static;
}

.avod-web-player-media, .avod-web-player-skin {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-skin {
  --button-size: 24px;
}

@container playerContainer (width > 768px) {
  .avod-web-player-skin {
    --button-size: 32px;
  }
}

button {
  font-family: inherit;
}

.avod-web-player-media {
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.avod-web-player-media video::cue {
  visibility: hidden;
}

.avod-web-player-media video::-webkit-media-text-track-display {
  display: none;
}

.avod-web-player-media video.main {
  object-fit: contain;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  width: 100%;
  display: block;
}

.avod-web-player-media video.padding-if-no-src:not([src]) {
  padding-bottom: var(--aspect-ratio, 56.25%);
  height: 0;
}

.avod-web-player-media .avod-web-player-csai {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-media .avod-web-player-csai video {
  width: 100%;
  height: 100%;
}

.avod-web-player-media .avod-web-player-pause {
  pointer-events: auto;
  cursor: pointer;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-media .avod-web-player-pause .pause-image {
  object-fit: contain;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.avod-web-player-media .avod-web-player-pause .pause-image:not([src]) {
  visibility: hidden;
}

.visit-advertiser-link {
  visibility: hidden;
  z-index: 2;
  color: #fff;
  cursor: pointer;
  background: rgba(0, 0, 0, .32);
  border: none;
  border-radius: 6px;
  -ms-flex-align: center;
  align-items: center;
  padding: .3em .6em;
  font-size: 1em;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 5em;
  right: 2em;
}

.visit-advertiser-link.desktop-small {
  bottom: 4em;
  right: 1em;
}

.visit-advertiser-link.mobile {
  bottom: 2.5em;
  right: 1em;
}

.visit-advertiser-link.smallest {
  font-size: .6em;
  bottom: 4.5em;
  right: .5em;
}

.visit-advertiser-link svg {
  fill: #fff;
  height: 2.5em;
}

.visit-advertiser-link.mobile svg {
  height: 2em;
}

.fullscreen {
  background-color: #000;
}

.fullscreen #container {
  position: static;
}

.avod-web-player-canvas-background {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-fake-fullscreen {
  background: #000;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.avod-web-player-fake-fullscreen > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.avod-web-player-fake-fullscreen #container {
  position: static;
}

/*# sourceMappingURL=index.b5464f33.css.map */
