.avod-web-player-container {
  container-name: playerContainer;
  container-type: inline-size;
  position: relative;
  height: 0;
  padding-top: var(--aspect-ratio, 56.25%);

  .dev_mode_notification {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, 0.5);
    text-shadow:
      1px 1px 0 rgba(255, 255, 255, 0.2),
      -1px -1px 0 rgba(0, 0, 0, 0.2);
    font-size: 2rem;
    z-index: 1;
    pointer-events: none;
    user-select: none;
  }

  &.fullscreen,
  &.avod-web-player-fake-fullscreen {
    padding-top: 0;
    position: static;
  }
}

.avod-web-player-media,
.avod-web-player-skin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avod-web-player-skin {
  --button-size: 24px;
}

@container playerContainer (width > 768px) {
  .avod-web-player-skin {
    --button-size: 32px;
  }
}

button {
  font-family: inherit;
}
