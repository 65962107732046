.avod-web-player-media {
  display: flex;
  justify-content: center;

  video::cue {
    visibility: hidden;
  }

  video::-webkit-media-text-track-display {
    display: none;
  }

  video.main {
    display: block;
    width: 100%;
    object-fit: contain;
    // Remove backdrop filters, they cause insane frame drops
    backdrop-filter: none;
  }

  video.padding-if-no-src {
    &:not([src]) {
      // make sure that the video is _atleast_ 16:9 even if no source is loaded
      // this makes sure that the CSAI video isn't overflowing the media container
      height: 0;
      padding-bottom: var(--aspect-ratio, 56.25%);
    }
  }

  .avod-web-player-csai {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;

    video {
      width: 100%;
      height: 100%;
    }
  }

  .avod-web-player-pause {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    cursor: pointer;
    background-color: black;

    .pause-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
      &:not([src]) {
        visibility: hidden;
      }
    }
  }
}

.visit-advertiser-link {
  position: absolute;
  visibility: hidden;
  bottom: 5em;
  right: 2em;
  z-index: 2;
  color: white;
  display: flex;
  align-items: center;
  padding: 0.3em 0.6em;
  background: rgba(0, 0, 0, 0.32);
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;

  &.desktop-small {
    bottom: 4em;
    right: 1em;
  }
  &.mobile {
    bottom: 2.5em;
    right: 1em;
  }

  &.smallest {
    font-size: 0.6em;
    right: 0.5em;
    bottom: 4.5em;
  }

  svg {
    fill: white;
    height: 2.5em;
  }

  &.mobile svg {
    height: 2em;
  }
}

.fullscreen {
  background-color: black;

  #container {
    position: static;
  }
}

.avod-web-player-canvas-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.avod-web-player-fake-fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;

  display: flex;
  justify-content: center;
  align-items: center;
  background: black;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #container {
    position: static;
  }
}
